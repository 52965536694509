import axiosService from '@/services/AxiosService'

export interface SelectOption{
  key: string;
  value: string;
  id: string | undefined;
}

class SelectOptionService
{
    optionCollection = new Map<string, Array<SelectOption>>()
    private optionsPromise: Promise<Array<Array<SelectOption>>> | null = null

    async getOptions(key: string, isEnum = true): Promise<Array<SelectOption>> {
      if(this.optionCollection.has(key)){
        return Promise.resolve(this.optionCollection.get(key) as Array<SelectOption>)
      }
      let optionsOfSpecificKey: Array<SelectOption>
      let data: any

      if(!isEnum)
      {
        data = (await axiosService.get('odata/metadatas?$filter=category eq \'industry_type\'&$orderby=isOther desc,sort asc')).data;
        optionsOfSpecificKey = data.value.map((m: { name: string; id: string }) => {
          return {
            key: m.name,
            value: m.id.toString()
          }
        })
      } else {
        data = (await axiosService.get((`api/metadatas/enums/${key}`))).data
        optionsOfSpecificKey = data.map((m: { label: string; value: number }) => {
          return {
            key: m.label,
            value: m.value
          }
        }) 
      }
      
      this.optionCollection.set(key, optionsOfSpecificKey)
      return optionsOfSpecificKey
    }

    async loadAllOptions(){
      if(!this.optionsPromise){
        this.optionsPromise = Promise.all([
        this.getOptions('industry_type', false), 
        // this.getOptions('CompanySource'),
        this.getOptions('ProjectStatus'), 
        this.getOptions('CompanyType'), 
        this.getOptions('ProjectType'),
        this.getOptions('CommunicationType')])
      }
      await this.optionsPromise
    }

    async waitOptionsLoadComplete(){
        if(!this.optionsPromise) {
          await this.loadAllOptions()
        } else {
          await this.optionsPromise
        }
    }
}

const _selectOptionService = new SelectOptionService()
export default _selectOptionService