import axios from "axios"
import { state } from '@/store'


//  Create 1 A axios Instances 
const  axiosService = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT, // url = base url + request url
  timeout: 10000 //  Set the timeout time to 10s
 })
 
 // request Interceptor  ==>  Processing request parameters 
 axiosService.interceptors.request.use(
  config => {
    let token = (state.userInfo || {}).token
    if(!token)
    {
      const savedCredential =  window.localStorage.getItem('user_info')
      if(savedCredential)
      {
          const savedCredentialJson = JSON.parse(savedCredential).token
          token = savedCredentialJson.token
          state.userInfo = savedCredentialJson;
      }
    }

    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config
  }, error => {
  //  Error processing request 
  console.log(error) // for debug
  Promise.reject(error)
  }
 )
 
 // respone Interceptor  ==>  Processing the response 
 axiosService.interceptors.response.use(
  response => {
    //  If the custom return code is not equal to 200,  Go back 1 Errors 
    if (response.status !== 200) {
      return Promise.reject(new Error(response.data || "Error"))
    }

    return response
  }, error => {
    //  Judge error Adj. status Code and inform the user of the corresponding information 
    let text = ""
    if (error.response && error.response.status && error.config.url != 'api/user/session') {
      switch (error.response.status) {
        case 400:
          text = " Request error (400) Please reapply "
          break
        case 401:
          text = " Login error (401) Please log in again "
          window.localStorage.removeItem('user_info')
          window.location.reload()
          break
        case 403:
          text = " Access denied (403)"
          break
        case 404:
          text = " Request error (404)"
          break
        case 408:
          text = " Request timeout (408)"
          break
        case 500:
          text = " Server error (500) Please restart the software or switch the function page! "
          break
        case 501:
          text = " Service not implemented (501)"
          break
        case 502:
          text = " Network error (502)"
          break
        case 503:
          text = " Service unavailable (503)"
          break
        case 504:
          text = " Network timeout (504)"
          break
        case 505:
          text = "HTTP Version is not supported (505)"
          break
        default:
          text = " Network connection error"
      }
    } else {
      text = " Failed to connect to server , Please exit and try again !"
    }
    
    console.error(text)
    return Promise.reject(error)
  })
 
 //  What will be written axios The instance is exposed 
 export default axiosService
