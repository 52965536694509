import { createStore } from 'vuex'

export interface UserInfo {
  token?: string;
  userName?: string;
  fullName?: string;
  roles?: string;
}

const state =  {
  isSidebarMinimized: false,
  userName: '',
  userInfo: {} as UserInfo
}

const store =  createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  state: state,
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    changeUserName(state, newUserName) {
      state.userName = newUserName
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
  },
  actions: {
    setUserInfo (context, userInfo) {
      context.commit('SET_USER_INFO', userInfo)
    },
  },
})

export { store, state }

