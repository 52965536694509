import { slotFlagsText } from '@vue/shared'

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'corporate-standard',
      displayName: '项目标准',
      meta: {
        icon: 'checklist',
      }
    },
    {
      name: 'coperate',
      displayName: '项目信息',
      meta: {
        icon: 'apartment',
      },
      disabled: true,
      children: [
        {
          name: 'corporate',
          displayName: '基本信息',
          meta: {
            icon: 'corporate_fare',
          }
        },
        {
          name: 'communication',
          displayName: '跟进记录',
          meta: {
            icon: 'mark_chat_unread',
          }
        },
        {
          name: 'settled-corporate',
          displayName: '已落户信息',
          meta: {
            icon: 'corporate_fare',
          }
        },
      ],
    },
    {
      name: 'project-search',
      displayName: '项目查询',
      meta: {
        icon: ' pivot_table_chart',
        hidden: true,
      },
      disabled: true,
      children: [
        {
          name: 'user-statistics',
          displayName: '用户项目',
          meta: {
            icon: 'person_search',
          }
        },
        {
          name: 'dept-statistics',
          displayName: '部门项目',
          meta: {
            icon: 'webhook',
          }
        },
      ]
    },
    {
      name: 'corporate-standard',
      displayName: '企业通讯录',
      meta: {
        icon: 'contacts',
        roles: [ 'admin', 'contact_management' ],
      },
      children: [
        {
          name: 'lead',
          displayName: '重点企业库',
          meta: {
            icon: 'perm_contact_calendar',
            roles: [ 'admin', 'contact_management' ],
          }
        },
        {
          name: 'lead-data-management',
          displayName: '数据管理',
          meta: {
            icon: 'storage',
            roles: [ 'admin', 'contact_management' ]
          }
        }
      ],
    },
    {
      name: 'statistics',
      displayName: '数据管理',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      disabled: true,
      children: [
        {
          name: 'data-import',
          displayName: '数据导入',
          meta: {
            icon: 'backup',
          }
        },
        {
          name: 'data-export',
          displayName: '数据导出',
          meta: {
            icon: 'cloud_download',
            roles: [ 'admin' ]
          }
        }
      ],
    },
    {
      name: 'privilege',
      displayName: '系统管理',
      meta: {
        icon: 'vuestic-iconset-settings',
        roles: [ 'admin' ]
      },
      disabled: true,
      children: [
        {
          name: 'duplicate-company',
          displayName: '项目查重',
          meta: {
            icon: 'difference',
          }
        },
        {
          name: 'department',
          displayName: '部门管理',
          meta: {
            icon: 'work',
          }
        },
        {
          name: 'user',
          displayName: '用户管理',
          meta: {
            icon: 'manage_accounts',
          }
        },
      ],
    }
  ],
}
