import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthLayout from '@/layout/auth-layout.vue'
import AppLayout from '@/layout/app-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'
import { store, UserInfo } from '../store'

import RouteViewComponent from './route-view.vue'
import UIRoute from '@/pages/admin/ui/route'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'corporate-standard' },
  },
  {
    name: 'main',
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        component: () => import('@/pages/corporate/Standard.vue')
      },
      {
        name: 'pages',
        path: 'pages',
        component: RouteViewComponent,
        children: [
          {
            name: '404-pages',
            path: '404-pages',
            component: () => import('@/pages/admin/pages/404PagesPage.vue'),
          },
          {
            name: 'faq',
            path: 'faq',
            component: () => import('@/pages/admin/pages/FaqPage.vue'),
          },
        ],
      },
      {
        name: 'corporate',
        path:'corporate',
        component: () => import('@/pages/corporate/CompanyList.vue'),
      },
      {
        name: 'duplicate-company',
        path:'duplicate-company',
        component: () => import('@/pages/corporate/CompanyList.vue'),
      },
      {
        name: 'settled-corporate',
        path:'settled-corporate',
        component: () => import('@/pages/corporate/CompanyList.vue'),
      },
      {
        name: 'edit-company',
        path: 'corporate/:companyId(\\d+)',
        component: () => import('@/pages/corporate/AddOrEditCompany.vue'),
      },
      {
        name: 'add-company',
        path: 'corporate/add-company',
        component: () => import('@/pages/corporate/AddOrEditCompany.vue'),
      },
      {
        name:'compnay-communication-summary',
        path: 'corporate/:companyId/communication-summary',
        component: () => import('@/pages/corporate/CommunicationSummary.vue'),
      },
      {
        name: 'corporate-standard',
        path:'corporate-standard',
        component: () => import('@/pages/corporate/Standard.vue'),
      },
      {
        name:'user-statistics',
        path: 'user-statistics',
        component: () => import('@/pages/statistics/User.vue'),
      },
      {
        name:'dept-statistics',
        path: 'dept-statistics',
        component: () => import('@/pages/statistics/Department.vue'),
      },
      {
        name:'user-corporate',
        path: 'user/:userId(\\d+)/corporate',
        component: () => import('@/pages/corporate/CompanyList.vue'),
      },
      {
        name:'dept-corporate',
        path: 'departments/:deptId(\\d+)/corporate',
        component: () => import('@/pages/corporate/CompanyList.vue'),
      },
      {
        name: 'communication',
        path:'communication',
        component: () => import('@/pages/communication/CommunicationList.vue'),
      },
      {
        name: 'add-communication',
        path: 'communication/add-communication',
        component: () => import('@/pages/communication/AddOrEditCommunication.vue'),
      },
      {
        name: 'edit-communication',
        path: 'communication/:communicationId(\\d+)',
        component: () => import('@/pages/communication/AddOrEditCommunication.vue'),
      },
      {
        name: 'data-import',
        path: 'data/import',
        component: () => import('@/pages/data-management/ImportData.vue'),
      },
      {
        name: 'data-export',
        path: 'data/export',
        component: () => import('@/pages/data-management/ExportData.vue'),
      },
      {
        name: 'user',
        path: 'permission/users',
        component: () => import('@/pages/permission/User.vue'),
      },
      {
        name: 'add-user',
        path: 'permission/users/add-user',
        component: () => import('@/pages/permission/AddOrEditUser.vue'),
      },
      {
        name: 'edit-user',
        path: 'permission/users/:userId(\\d+)',
        component: () => import('@/pages/permission/AddOrEditUser.vue'),
      },
      {
        name: 'department',
        path: 'permission/departments',
        component: () => import('@/pages/permission/Department.vue'),
      },
      {
        name: 'add-department',
        path: 'permission/departments/add-department',
        component: () => import('@/pages/permission/AddOrEditDepartment.vue'),
      },
      {
        name: 'edit-department',
        path: 'permission/departments/:deparmentId(\\d+)',
        component: () => import('@/pages/permission/AddOrEditDepartment.vue'),
      },
      {
        name: "update-password",
        path: 'auth/update-password',
        component: () => import('@/pages/user/UpdatePassword.vue')
      },
      {
        name: "lead",
        path: 'lead',
        component: () => import('@/pages/lead/LeadList.vue')
      },
      {
        name: 'add-lead',
        path: 'lead/add-lead',
        component: () => import('@/pages/lead/AddOrEditLead.vue'),
      },
      {
        name: 'edit-lead',
        path: 'lead/:leadId(\\d+)',
        component: () => import('@/pages/lead/AddOrEditLead.vue'),
      },
      {
        name: "lead-data-management",
        path: 'lead/data-management',
        component: () => import('@/pages/lead/DataManagement.vue')
      },
      UIRoute,
    ]
  },
  {
    name: "login",
    path: '/auth/login',
    component: () => import('@/pages/auth/login/Login.vue'),
    meta: {
      allowAnonymous: true
    }
  },
  {
    name: "signoff",
    path: '/auth/signoff',
    component: () => import('@/pages/auth/login/Signoff.vue')
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'signup',
        path: 'signup',
        component: () => import('@/pages/auth/signup/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/404',
    component: Page404Layout,
    children: [
      {
        name: 'not-found-advanced',
        path: 'not-found-advanced',
        component: () => import('@/pages/404-pages/VaPageNotFoundSearch.vue'),
      },
      {
        name: 'not-found-simple',
        path: 'not-found-simple',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-custom',
        path: 'not-found-custom',
        component: () => import('@/pages/404-pages/VaPageNotFoundCustom.vue'),
      },
      {
        name: 'not-found-large-text',
        path: '/pages/not-found-large-text',
        component: () => import('@/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(window.localStorage.getItem('user_info') || '{}') as UserInfo
  if (!to.meta.allowAnonymous && !user.token) {
    next('/auth/login')
  } else {
    if (!store.state.userInfo.token) {
      store.dispatch('setUserInfo', user)
    }
    next()
  }
})

export default router
