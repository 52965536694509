<template>
  <va-sidebar
    :width="width"
    :minimized="minimized" 
    :minimizedWidth="minimizedWidth"
  >
    <menu-minimized v-if="minimized" :items="items" />
    <menu-accordion v-else :items="items" />
  </va-sidebar>    
</template>
<script lang="ts">
import { useGlobalConfig } from 'vuestic-ui';
import { defineComponent, computed } from 'vue'
import MenuAccordion from './menu/MenuAccordion.vue';
import MenuMinimized from './menu/MenuMinimized.vue';
import NavigationRoutes from './NavigationRoutes';
import { useStore } from 'vuex'

export default defineComponent({
  name: "app-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
  },
  props: {
    width: { type: String, default: '16rem' },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const store = useStore()
    const items = computed(() => {
      const roles = store.state.userInfo.roles.split(',')
      return NavigationRoutes.routes.filter(r => !r.meta.roles || roles.some((_r : string) => r.meta.roles?.some((_rn : string) => _rn == _r)))
    })

    const computedClass = computed(() => {
      return {
        "app-sidebar--minimized": props.minimized
      }
    })

    const colors = computed(() => {
      return useGlobalConfig().getGlobalConfig().colors
    })

    return {
      items,
      computedClass,
      colors
    }
  },
})
</script>


<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
    padding-left: .5rem;
  }

  &__menu {
    padding: 2rem 0;
    &__inner {
      padding-bottom: 8rem;
    }
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
